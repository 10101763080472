import React from "react";
import { useNavigate } from "react-router";
import { connect } from "react-redux";

const mapStateToProps = (state: any) => {
  return {
    path: state.path,
    services: state.services,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    setPath: (path: string) => dispatch({ type: "SET_PATH", path: path }),
  };
};

const Home = ({ path, setPath, services }: any) => {
  const [selectedService, setSelectedService] = React.useState<number>(1);

  const navigate = useNavigate();
  const handleNavigate = (path: string) => {
    navigate(path);
    sessionStorage.setItem("path", path);
    setPath(path);
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div className="main-banner header-text" id="top">
        <div className="Modern-Slider">
          <div className="item item-1">
            <div className="img-fill">
              <div className="text-content">
                <h6 className="mb-0">
                  Welcome to Lebanon’s First Electric Vehicle All in one
                  Platform
                </h6>
                <p className="m-0 py-3 text-uppercase">where you will find</p>

                <h4>all your EV needs in one place</h4>
                <a
                  className="filled-button"
                  onClick={() => handleNavigate("preRegister")}>
                  Pre-Register
                </a>
              </div>
            </div>
          </div>

          {/* <div className="item item-2">
            <div className="img-fill">
              <div className="text-content">
                <h6>magni deserunt dolorem harum quas!</h6>
                <h4>
                  Aliquam iusto harum <br /> ratione porro odio
                </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. At
                  culpa cupiditate mollitia adipisci assumenda laborum eius quae
                  quo excepturi, eveniet. Dicta nulla ea beatae consequuntur?
                </p>
                <a ="about.html" className="filled-button">
                  About Us
                </a>
              </div>
            </div>
          </div>

          <div className="item item-3">
            <div className="img-fill">
              <div className="text-content">
                <h6>alias officia qui quae vitae natus!</h6>
                <h4>
                  Lorem ipsum dolor <br />
                  sit amet, consectetur.
                </h4>
                <p>
                  Vivamus ut tellus mi. Nulla nec cursus elit, id vulputate mi.
                  Sed nec cursus augue. Phasellus lacinia ac sapien vitae
                  dapibus. Mauris ut dapibus velit cras interdum nisl ac urna
                  tempor mollis.
                </p>
                <a ="contact.html" className="filled-button">
                  Contact Us
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <div className="request-form">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h4>Let's talk! </h4>
              <span>Contact us for any questions or assistance.</span>
            </div>
            <div className="col-md-4">
              <a
                className="border-button"
                onClick={() => handleNavigate("/contact")}>
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="services">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-heading">
                <h2>
                  Featured <em>Cars</em>
                </h2>
                <span>Aliquam id urna imperdiet libero mollis hendrerit</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="service-item">
                <img src="/assets/images/product-1-720x480.jpg" alt="" />
                <div className="down-content">
                  <h4>Lorem ipsum dolor sit amet</h4>
                  <div style={{ marginBottom: "10px" }}>
                    <span>
                      <del>
                        <sup>$</sup>11999{" "}
                      </del>{" "}
                      &nbsp; <sup>$</sup>11779
                    </span>
                  </div>

                  <p>
                    <i className="fa fa-dashboard"></i> 130 000km
                    &nbsp;&nbsp;&nbsp;
                    <i className="fa fa-cube"></i> 1800 cc &nbsp;&nbsp;&nbsp;
                    <i className="fa fa-cog"></i> Manual &nbsp;&nbsp;&nbsp;
                  </p>
                  <a className="filled-button">View More</a>
                </div>
              </div>

              <br />
            </div>
            <div className="col-md-4">
              <div className="service-item">
                <img src="/assets/images/product-2-720x480.jpg" alt="" />
                <div className="down-content">
                  <h4>Lorem ipsum dolor sit amet</h4>
                  <div style={{ marginBottom: "10px" }}>
                    <span>
                      <del>
                        <sup>$</sup>11999{" "}
                      </del>{" "}
                      &nbsp; <sup>$</sup>11779
                    </span>
                  </div>

                  <p>
                    <i className="fa fa-dashboard"></i> 130 000km
                    &nbsp;&nbsp;&nbsp;
                    <i className="fa fa-cube"></i> 1800 cc &nbsp;&nbsp;&nbsp;
                    <i className="fa fa-cog"></i> Manual &nbsp;&nbsp;&nbsp;
                  </p>
                  <a className="filled-button">View More</a>
                </div>
              </div>

              <br />
            </div>
            <div className="col-md-4">
              <div className="service-item">
                <img src="/assets/images/product-3-720x480.jpg" alt="" />
                <div className="down-content">
                  <h4>Lorem ipsum dolor sit amet</h4>
                  <div style={{ marginBottom: "10px" }}>
                    <span>
                      <del>
                        <sup>$</sup>11999{" "}
                      </del>{" "}
                      &nbsp; <sup>$</sup>11779
                    </span>
                  </div>

                  <p>
                    <i className="fa fa-dashboard"></i> 130 000km
                    &nbsp;&nbsp;&nbsp;
                    <i className="fa fa-cube"></i> 1800 cc &nbsp;&nbsp;&nbsp;
                    <i className="fa fa-cog"></i> Manual &nbsp;&nbsp;&nbsp;
                  </p>
                  <a className="filled-button">View More</a>
                </div>
              </div>

              <br />
            </div>
          </div>
        </div>
      </div> */}

      <div className="fun-facts mt-0">
        <div className="container">
          <div className="more-info-content">
            <div className="row">
              <div className="col-md-6">
                <div className="left-image">
                  <img
                    src="/assets/images/Showroom-wanna buy an ev.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 align-self-center">
                <div className="right-content">
                  {/* <span>Who we are</span> */}
                  <h2>{services[0].name}</h2>
                  {services[0].desc.map((item: any) => (
                    <p className="mb-0">{item}</p>
                  ))}
                  {/* <a className="filled-button">Read More</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="more-info mb-5">
        <div className="container">
          {/* <div className="section-heading">
            <h2>
              Our <em>Services</em>
            </h2>
          </div> */}

          <div className="row">
            <div className="col-md-6 align-self-center">
              <div className="right-content">
                {/* <span>Who we are</span> */}
                <h2>{services[1].name}</h2>
                {services[1].desc.map((item: any) => (
                  <p className="mb-0">{item}</p>
                ))}
                {/* <a className="filled-button">Read More</a> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="left-image">
                <img
                  src="/assets/images/Charging stations map-already own an ev.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="fun-facts one mt-0">
        <div className="container">
          <div className="more-info-content">
            <div className="row">
              <div className="col-md-6">
                <div className="left-image">
                  <img
                    src="/assets/images/forums.PNG"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 align-self-center">
                <div className="right-content">
                  {/* <span>Who we are</span> */}
                  <h2>{services[3].name}</h2>
                  <p>
                    {services[3].desc.map((item: any) => (
                      <p className="mb-0">{item}</p>
                    ))}
                  </p>
                  {/* <a className="filled-button">Read More</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="more-info mb-5">
        <div className="container">
          {/* <div className="section-heading">
            <h2>
              Our <em>Services</em>
            </h2>
          </div> */}

          <div className="row">
            <div className="col-md-6 align-self-center">
              <div className="right-content">
                {/* <span>Who we are</span> */}
                <h2>{services[2].name}</h2>

                {services[2].desc}

                {/* <a className="filled-button">Read More</a> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="left-image">
                <img
                  src="/assets/images/dashboard.jpeg"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="more-info">
        <div className="container">
          <div className="section-heading">
            <h2>
              Read our <em>Blog</em>
            </h2>
            <span>Aliquam id urna imperdiet libero mollis hendrerit</span>
          </div>

          <div className="row" id="tabs">
            <div className="col-md-4">
              <ul>
                <li>
                  <a>
                    Lorem ipsum dolor sit amet, consectetur adipisicing <br />{" "}
                    <small>John Doe &nbsp;|&nbsp; 27.07.2020 10:10</small>
                  </a>
                </li>
                <li>
                  <a>
                    Mauris lobortis quam id dictum dignissim <br />{" "}
                    <small>John Doe &nbsp;|&nbsp; 27.07.2020 10:10</small>
                  </a>
                </li>
                <li>
                  <a>
                    className aptent taciti sociosqu ad litora torquent per{" "}
                    <br />{" "}
                    <small>John Doe &nbsp;|&nbsp; 27.07.2020 10:10</small>
                  </a>
                </li>
              </ul>

              <b />

              <div className="text-center mt-2">
                <a className="filled-button">Read More</a>
              </div>

              <br />
            </div>

            <div className="col-md-8">
              <section className="tabs-content" id="tab-content">
                <article id="tabs-1">
                  <img src="/assets/images/blog-image-1-940x460.jpg" alt="" />
                  <h4>
                    <a>Lorem ipsum dolor sit amet, consectetur adipisicing.</a>
                  </h4>
                  <p>
                    Sed ut dolor in augue cursus ultrices. Vivamus mauris
                    turpis, auctor vel facilisis in, tincidunt vel diam. Sed
                    vitae scelerisque orci. Nunc non magna orci. Aliquam commodo
                    mauris ante, quis posuere nibh vestibulum sit amet.
                  </p>
                </article>
                <article id="tabs-2">
                  <img src="/assets/images/blog-image-2-940x460.jpg" alt="" />
                  <h4>
                    <a>Mauris lobortis quam id dictum dignissim</a>
                  </h4>
                  <p>
                    Sed ut dolor in augue cursus ultrices. Vivamus mauris
                    turpis, auctor vel facilisis in, tincidunt vel diam. Sed
                    vitae scelerisque orci. Nunc non magna orci. Aliquam commodo
                    mauris ante, quis posuere nibh vestibulum sit amet
                  </p>
                </article>
                <article id="tabs-3">
                  <img src="/assets/images/blog-image-3-940x460.jpg" alt="" />
                  <h4>
                    <a>
                      className aptent taciti sociosqu ad litora torquent per
                    </a>
                  </h4>
                  <p>
                    Mauris lobortis quam id dictum dignissim. Donec pellentesque
                    erat dolor, cursus dapibus turpis hendrerit quis.
                    Suspendisse at suscipit arcu. Nulla sed erat lectus. Nulla
                    facilisi. In sit amet neque sapien. Donec scelerisque mi at
                    gravida efficitur. Nunc lacinia a est eu malesuada.
                    Curabitur eleifend elit sapien, sed pulvinar orci luctus
                    eget.
                  </p>
                </article>
              </section>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="testimonials">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-heading">
                <h2>
                  What they say <em>about us</em>
                </h2>
                <span>testimonials from our greatest clients</span>
              </div>
            </div>
            <div className="col-md-12">
              <div className="owl-testimonials owl-carousel">
                <div className="testimonial-item">
                  <div className="inner-content">
                    <h4>George Walker</h4>
                    <span>Chief Financial Analyst</span>
                    <p>
                      "Nulla ullamcorper, ipsum vel condimentum congue, mi odio
                      vehicula tellus, sit amet malesuada justo sem sit amet
                      quam. Pellentesque in sagittis lacus."
                    </p>
                  </div>
                  <img src="http://placehold.it/60x60" alt="" />
                </div>

                <div className="testimonial-item">
                  <div className="inner-content">
                    <h4>John Smith</h4>
                    <span>Market Specialist</span>
                    <p>
                      "In eget leo ante. Sed nibh leo, laoreet accumsan euismod
                      quis, scelerisque a nunc. Mauris accumsan, arcu id ornare
                      malesuada, est nulla luctus nisi."
                    </p>
                  </div>
                  <img src="http://placehold.it/60x60" alt="" />
                </div>

                <div className="testimonial-item">
                  <div className="inner-content">
                    <h4>David Wood</h4>
                    <span>Chief Accountant</span>
                    <p>
                      "Ut ultricies maximus turpis, in sollicitudin ligula
                      posuere vel. Donec finibus maximus neque, vitae egestas
                      quam imperdiet nec. Proin nec mauris eu tortor consectetur
                      tristique."
                    </p>
                  </div>
                  <img src="http://placehold.it/60x60" alt="" />
                </div>

                <div className="testimonial-item">
                  <div className="inner-content">
                    <h4>Andrew Boom</h4>
                    <span>Marketing Head</span>
                    <p>
                      "Curabitur sollicitudin, tortor at suscipit volutpat, nisi
                      arcu aliquet dui, vitae semper sem turpis quis libero.
                      Quisque vulputate lacinia nisl ac lobortis."
                    </p>
                  </div>
                  <img src="http://placehold.it/60x60" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
