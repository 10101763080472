import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Modules from "./modules/Modules";

function App() {
  return <Modules />;
}

export default App;
