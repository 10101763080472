import React from "react";
import moment from "moment";
import { fetchApi } from "../../../Utils/Connections";
import Spinner from "../components/Spinner";

export default function PreRegister() {
  const [FullName, setName] = React.useState<string>("");
  const [Email, setEmail] = React.useState<string>("");
  const [Phone, setPhone] = React.useState<string>("");
  const [Address, setAddress] = React.useState<string>("");
  const [IdAccountType, setSelectedUser] = React.useState<number>(0);
  const [DateOfBirth, setDateOfBirth] = React.useState<any>("");
  const [JobTitle, setJobTitle] = React.useState<string>("");
  const [BusinessName, setBusinessName] = React.useState<string>("");

  const [success, setSuccess] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    console.log(DateOfBirth);
  }, [DateOfBirth]);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const timestamp = Date.now();
    if (DateOfBirth > timestamp) {
      alert("Please select a past date");
      return;
    }
    setLoading(true);
    fetchApi("Client/PreRegister", "POST", {
      FullName,
      Email,
      Phone,
      Address,
      IdAccountType,
      DateOfBirth,
      JobTitle,
      BusinessName,
    }).then((result) => {
      if (result.StatusCode === 200) {
        alert("Pre-Registeration Sent Successfully");
      } else {
        alert("Pre-Registeration Failed to Send");
      }
      setLoading(false);
    });
  };

  const handleDateOfBirth = (date: any) => {
    setDateOfBirth(moment(date.target.valueAsNumber).format("yyyy-MM-DD"));
  };
  return (
    <>
      <div className="page-heading header-text">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Pre-Register</h1>
              <span style={{ height: "2rem" }}></span>
            </div>
          </div>
        </div>
      </div>
      <div className="callback-form contact-us pt-1">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-heading">
                <h2>{/* Pre-<em>Register</em> */}</h2>
                {/* <span>Suspendisse a ante in neque iaculis lacinia</span> */}
              </div>
            </div>
            <div className="col-md-12">
              <div className="contact-form">
                <form id="contact" onSubmit={(e) => handleSubmit(e)}>
                  <div className="row">
                    <div className=" col-md-12 col-sm-12">
                      <label htmlFor="Full Name">Full Name*</label>
                      <fieldset>
                        <input
                          name="Full Name"
                          type="text"
                          className="form-control"
                          id="Full Name"
                          placeholder="Full Name"
                          value={FullName}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </fieldset>
                    </div>
                    <div className=" col-md-12 col-sm-12">
                      <label htmlFor="email">E-Mail*</label>

                      <fieldset>
                        <input
                          name="email"
                          type="email"
                          className="form-control"
                          id="email"
                          pattern="[^ @]*@[^ @]*"
                          placeholder="E-Mail Address"
                          value={Email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </fieldset>
                    </div>
                    <div className=" col-md-12 col-sm-12">
                      <label htmlFor="phone">Phone Number*</label>

                      <fieldset>
                        <input
                          name="phone"
                          type="text"
                          className="form-control"
                          id="phone"
                          placeholder="Phone Number"
                          value={Phone}
                          onChange={(e) => setPhone(e.target.value)}
                          required
                        />
                      </fieldset>
                    </div>
                    <div className=" col-md-12 col-sm-12">
                      <label htmlFor="Address">Address*</label>

                      <fieldset>
                        <input
                          name="Address"
                          type="text"
                          className="form-control"
                          id="Address"
                          onChange={(e) => setAddress(e.target.value)}
                          value={Address}
                          placeholder="Address"
                          required
                        />
                      </fieldset>
                    </div>
                    <div className=" col-md-12 col-sm-12 d-flex justify-content-center flex-column">
                      <label htmlFor="User Type" className="">
                        Account*
                      </label>

                      <fieldset className="w-100">
                        <div className="">
                          <div className="col-6 d-flex align-items-centers">
                            <input
                              type="radio"
                              id="Individual"
                              className=""
                              name="userType"
                              value={"1"}
                              onChange={(e) =>
                                setSelectedUser(JSON.parse(e.target.value))
                              }
                              style={{
                                scale: "0.5",
                                margin: "0",
                                width: "3rem",
                              }}
                            />
                            <label
                              htmlFor="Individual"
                              className="d-flex align-items-center">
                              Individual
                            </label>
                          </div>
                          <div className="col-6 d-flex align-items-center">
                            <input
                              type="radio"
                              className="form-control"
                              name="userType"
                              id="EV Seller or Provider"
                              value={"2"}
                              onChange={(e) =>
                                setSelectedUser(JSON.parse(e.target.value))
                              }
                              style={{
                                scale: "0.5",
                                margin: "0",
                                width: "3rem",
                              }}
                            />
                            <label
                              htmlFor="EV Seller or Provider"
                              className="d-flex align-items-center">
                              EV Seller or Provider
                            </label>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    {IdAccountType === 0 ? null : IdAccountType === 1 ? (
                      <>
                        <div className=" col-md-12 col-sm-12">
                          <label htmlFor="Date of Birth">Date of Birth*</label>

                          <fieldset>
                            <input
                              name="Date of Birth"
                              type="date"
                              className="form-control"
                              id="Date of Birth"
                              onChange={handleDateOfBirth}
                              value={DateOfBirth}
                              placeholder="Date of birth"
                              required
                            />
                          </fieldset>
                        </div>
                        <div className="col-md-12 col-sm-12">
                          <label htmlFor="Job Title">Job Title</label>

                          <fieldset>
                            <input
                              name="Job Title"
                              type="text"
                              className="form-control"
                              id="Job Title"
                              onChange={(e) => setJobTitle(e.target.value)}
                              value={JobTitle}
                              placeholder="Job Title"
                            />
                          </fieldset>
                        </div>
                      </>
                    ) : (
                      <div className=" col-md-12 col-sm-12">
                        <label htmlFor="Buisiness Name">Buisiness Name*</label>

                        <fieldset>
                          <input
                            name="Buisiness Name"
                            type="text"
                            className="form-control"
                            id="Buisiness Name"
                            placeholder="Buisiness Name"
                            onChange={(e) => setBusinessName(e.target.value)}
                            value={BusinessName}
                            required
                          />
                        </fieldset>
                      </div>
                    )}
                    <div className="col-lg-12">
                      <fieldset className="text-center">
                        <button
                          type="submit"
                          id="form-submit"
                          className="filled-button"
                          disabled={loading}>
                          {loading ? <Spinner /> : "Pre Register"}
                        </button>
                      </fieldset>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
