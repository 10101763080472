import React from "react";
import { fetchApi } from "../../../Utils/Connections";
import Spinner from "../components/Spinner";

export default function ContactUs() {
  const [FullName, setName] = React.useState<string>("");
  const [Email, setEmail] = React.useState<string>("");
  const [Subject, setSubject] = React.useState<string>("");
  const [Message, setMessage] = React.useState<string>("");

  const [success, setSuccess] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const SendMessage = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const result = await fetchApi("Client/ContactUs", "POST", {
      FullName,
      Email,
      Subject,
      Message,
    });
    if (result.StatusCode === 200) {
      alert("Message Sent Successfully");
      setSuccess(true);
      setError(false);
    } else {
      alert("Message Failed to Send");
      setError(true);
      setSuccess(false);
    }
    setLoading(false);
  };
  return (
    <>
      <div className="page-heading header-text">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Contact Us</h1>
              <span>feel free to send us a message now!</span>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-information">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-4">
              <div className="contact-item">
                <i className="fa fa-phone"></i>
                <h4>Phone</h4>
                {/* <p>
                  Vivamus ut tellus mi. Nulla nec cursus elit, id vulputate nec
                  cursus augue.
                </p> */}
                <a href="tel:961 76 663 881">+961 76 663 881</a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact-item">
                <i className="fa fa-envelope"></i>
                <h4>Email</h4>
                {/* <p>
                  Vivamus ut tellus mi. Nulla nec cursus elit, id vulputate nec
                  cursus augue.
                </p> */}
                <a href="mailto:">contact@evxshowroom.com</a>
              </div>
            </div>
            {/* <div className="col-md-4">
              <div className="contact-item">
                <i className="fa fa-map-marker"></i>
                <h4>Location</h4>
                <p>
                  212 Barrington Court New York str <br /> USA
                </p>
                <a href="#">View on Google Maps</a>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="callback-form contact-us mt-4">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-heading">
                <h2>
                  Send us a <em>message</em>
                </h2>
                <span>Contact us for any questions or assistance.</span>
              </div>
            </div>
            <div className="col-md-12">
              <div className="contact-form">
                <form id="contact" onSubmit={(e) => SendMessage(e)}>
                  <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12">
                      <fieldset>
                        <input
                          name="name"
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Full Name"
                          value={FullName}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </fieldset>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12">
                      <fieldset>
                        <input
                          name="email"
                          type="text"
                          className="form-control"
                          id="email"
                          pattern="[^ @]*@[^ @]*"
                          placeholder="E-Mail Address"
                          value={Email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </fieldset>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12">
                      <fieldset>
                        <input
                          name="subject"
                          type="text"
                          className="form-control"
                          id="subject"
                          placeholder="Subject"
                          value={Subject}
                          onChange={(e) => setSubject(e.target.value)}
                          required
                        />
                      </fieldset>
                    </div>
                    <div className="col-lg-12">
                      <fieldset>
                        <textarea
                          name="message"
                          rows={6}
                          className="form-control"
                          id="message"
                          placeholder="Your Message"
                          value={Message}
                          onChange={(e) => setMessage(e.target.value)}
                          required></textarea>
                      </fieldset>
                    </div>
                    <div className="col-lg-12 d-flex justify-content-center">
                      {success && (
                        <h4 className="text-success">
                          Messege Sent Successfully
                        </h4>
                      )}
                      {error && (
                        <h4 className="text-danger">Failed to Send Message</h4>
                      )}
                    </div>
                    <div className="col-lg-12 d-flex justify-content-center">
                      <fieldset>
                        <button
                          type="submit"
                          id="form-submit"
                          className="filled-button"
                          disabled={loading}>
                          {loading ? <Spinner /> : "Send Message"}
                        </button>
                      </fieldset>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div id="map">
        <iframe
          src="https://maps.google.com/maps?q=Av.+Lúcio+Costa,+Rio+de+Janeiro+-+RJ,+Brazil&t=&z=13&ie=UTF8&iwloc=&output=embed"
          width="100%"
          height="500px"
          style={{ border: 0 }}></iframe>
      </div> */}
    </>
  );
}
