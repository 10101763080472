const initialState = {
  path: sessionStorage.getItem("adminPath") || "/",
  token: sessionStorage.getItem("Token") || null,
  sideBar: false,
  navigation: [
    {
      id: 1,
      name: "Contact",
      path: "/admin/dashboard/contacts",
    },
    {
      id: 2,
      name: "Pre-Register",
      path: "/admin/dashboard/preRegister",
    },
    {
      id: 3,
      name: "Logout",
      path: "/admin/login",
    },
  ],
  selectedContact: null,
};

const mainReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_PATH":
      return {
        ...state,
        path: action.path,
      };
    case "SET_SIDEBAR":
      return {
        ...state,
        sideBar: action.sideBar,
      };
    case "SET_SELECTED_CONTACT":
      return {
        ...state,
        selectedContact: action.selectedContact,
      };
    case "SET_TOKEN":
      return {
        ...state,
        token: action.token,
      };
    default:
      return state;
  }
};

export default mainReducer;
