import React from "react";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import { fetchApi } from "../../../Utils/Connections";
import Spinner from "../componants/Spinner";

const mapStateToProps = (state: any) => {
  return {
    path: state.path,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    setPath: (path: string) => dispatch({ type: "SET_PATH", path: path }),
    setToken: (token: string) => dispatch({ type: "SET_TOKEN", token: token }),
  };
};

const Login = ({ path, setPath, setToken }: any) => {
  const _login = async () => {
    setLoading(true);
    if (!username || !password) return alert("Please fill all fields");

    const result = await fetchApi("Admin/Login", "POST", {
      Username: username,
      Password: password,
    });
    if (result.StatusCode === 200) {
      sessionStorage.setItem("Token", result.My_Result.JWT_Token);
      setToken(result.My_Result.JWT_Token);
      handleNavigate("/admin/dashboard/contacts");
      setError(false);
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const navigate = useNavigate();

  const handleNavigate = (path: string) => {
    navigate(path);
    sessionStorage.setItem("adminPath", path);
    setPath(path);
    window.scrollTo(0, 0);
  };

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);

  return (
    <div
      className="w-100 d-flex align-items-center justify-content-center"
      style={{ height: "100vh", background: "#5addf6" }}>
      <div
        className="bg-light shadow p-3 rounded d-flex flex-column justify-content-center align-items-center"
        style={{ width: "25rem", maxWidth: "90%" }}>
        <h4>EVX</h4>
        <div className="d-flex flex-column justify-content-center align-items-center mt-3 w-100">
          <input
            type="text"
            placeholder="Username"
            className="form-control my-2"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <div className="d-flex mb-2 w-100 align-items-center">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              className="form-control w-100"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <i
              className={`bi bi-${!showPassword ? "eye-slash" : "eye"}`}
              style={{ margin: "-1.5rem" }}
              onClick={() => setShowPassword(!showPassword)}></i>
          </div>
          {error && (
            <div className="text-center text-danger">
              Wrong Username or Password
            </div>
          )}
          <button
            type="submit"
            className="filled-button m-0 w-100"
            onClick={() => _login()}>
            {loading ? <Spinner /> : "Login"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
