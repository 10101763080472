import React from "react";
import { Routes, Route } from "react-router-dom";
import Navigation from "./main/navigation/Navigation";
import AdminNavigation from "./admin/navigation/Navigation";
import { createStore } from "redux";
import { Provider } from "react-redux";
import mainReducer from "../reduces/mainReducer";
import adminReducer from "../reduces/adminReducer";

const mainStore = createStore(mainReducer);
const adminStore = createStore(adminReducer);

export default function Modules() {
  return (
    <>
      <Routes>
        <Route
          path="/admin/*"
          element={
            <Provider store={adminStore}>
              <AdminNavigation />
            </Provider>
          }
        />
        <Route
          path="/*"
          element={
            <Provider store={mainStore}>
              <Navigation />
            </Provider>
          }
        />
      </Routes>
    </>
  );
}
