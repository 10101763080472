import React from "react";
import { useNavigate } from "react-router";
import { Outlet } from "react-router-dom";
import { connect } from "react-redux";

const mapStateToProps = (state: any) => {
  return {
    path: state.path,
    navigation: state.navigation,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    setPath: (path: string) => dispatch({ type: "SET_PATH", path: path }),
  };
};
const NavBar = ({ navigation, path, setPath }: any) => {
  const navigate = useNavigate();
  const handleNavigate = (path: string) => {
    setPath(path);
    navigate(path);
    sessionStorage.setItem("adminPath", path);
    window.scrollTo(0, 0);
  };
  return (
    <>
      <header className="shadow" style={{ background: "white" }}>
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <a
              className="pl-2 d-flex align-items-center justify-content-center"
              href="index.html">
              {/* <h2>
                Car <em> Website</em>
              </h2> */}
              <img
                src="/assets/images/EVX logo2.png"
                style={{ maxHeight: "2.5rem" }}
                alt=""
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ml-auto">
                {/* <li className="nav-item active">
                <a className="nav-link" href="index.html">
                  Home
                  <span className="sr-only">(current)</span>
                </a>
              </li> */}
                {navigation.map((nav: any) => (
                  <li className="nav-item" key={nav.id}>
                    <a
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarResponsive"
                      aria-controls="navbarResponsive"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                      className={`nav-link ${path === nav.path && "active"}`}
                      style={{ color: "black" }}
                      onClick={() => {
                        handleNavigate(nav.path);
                        if (nav.name === "Logout") {
                          sessionStorage.removeItem("adminPath");
                        }
                      }}>
                      {nav.name}
                    </a>
                  </li>
                ))}
                {/* <li className="nav-item dropdown">
                <a
                  className="dropdown-toggle nav-link"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false">
                  About
                </a>
    
                <div className="dropdown-menu">
                  <a className="dropdown-item" href="about.html">
                    About Us
                  </a>
                  <a className="dropdown-item" href="blog.html">
                    Blog
                  </a>
                  <a className="dropdown-item" href="team.html">
                    Team
                  </a>
                  <a className="dropdown-item" href="testimonials.html">
                    Testimonials
                  </a>
                  <a className="dropdown-item" href="faq.html">
                    FAQ
                  </a>
                  <a className="dropdown-item" href="terms.html">
                    Terms
                  </a>
                </div>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="contact.html">
                  Contact Us
                </a>
              </li> */}
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <Outlet />
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
