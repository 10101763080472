import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import NavBar from "./NavBar";
import Login from "../pages/Login";
import Contact from "../pages/Contact";
import PreRegister from "../pages/PreRegister";
import { connect } from "react-redux";

const mapStateToProps = (state: any) => {
  return {
    loggedIn: state.loggedIn,
    path: state.path,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    login: () => dispatch({ type: "LOG_IN" }),
    logout: () => dispatch({ type: "LOG_OUT" }),
    setPath: (path: string) => dispatch({ type: "SET_PATH", path: path }),
  };
};
const Navigation = ({ path, setPath }: any) => {
  React.useEffect(() => {
    setPath(sessionStorage.getItem("adminPath") || "/admin/login");
  }, []);

  React.useEffect(() => {
    const handleBackButtonClick = () => {
      setPath(window.location.pathname);
      sessionStorage.setItem("adminPath", window.location.pathname);
    };

    window.addEventListener("popstate", handleBackButtonClick);

    // Cleanup the listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handleBackButtonClick);
    };
  }, []);

  return (
    <>
      {path !== "/admin/login" && <NavBar />}
      <Routes>
        <Route path="/" element={<Navigate to="/admin/login" />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <>
              <Navigate to="/admin/dashboard/contact" />
            </>
          }
        />
        <Route path="/dashboard/contacts" element={<Contact />} />
        <Route path="/dashboard/preRegister" element={<PreRegister />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
