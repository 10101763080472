import React from "react";
import ContactSideBar from "../componants/ContactSideBar";
import { connect } from "react-redux";
import { fetchApi } from "../../../Utils/Connections";
import Spinner from "../componants/Spinner";
import { off } from "process";

const mapStateToProps = (state: any) => {
  return {
    open: state.sideBar,
    selectedContact: state.selectedContact,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    setOpen: (sidebar: boolean) =>
      dispatch({ type: "SET_SIDEBAR", sideBar: sidebar }),
    setSelectedContact: (contact: any) =>
      dispatch({ type: "SET_SELECTED_CONTACT", selectedContact: contact }),
  };
};

const Contact = ({
  open,
  setOpen,
  seletedContact,
  setSelectedContact,
}: any) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);

  const [sort, setSort] = React.useState<string>("Date");
  const [offset, setOffset] = React.useState<number>(0);

  const [contacts, setContacts] = React.useState<any>([]);

  const [hasMore, setHasMore] = React.useState<boolean>(true);

  const getContacts = async () => {
    setLoading(true);
    setHasMore(true);
    const result = await fetchApi(
      `Admin/GetContacts?Offset=${offset}&OrderType=${sort}`,
      "GET",
      {}
    );
    if (result.StatusCode === 200) {
      if (result.My_Result.length === 0 && offset !== 0) {
        setHasMore(false);
        setLoading(false);
        return;
      }
      let c = [];
      if (offset === 0) {
        c = result.My_Result;
      } else {
        c = [...contacts, ...result.My_Result];
      }
      setContacts(c);
    } else {
      setError(true);
      alert("Something went wrong, try again.");
    }
    setLoading(false);
  };

  React.useEffect(() => {
    console.log("offset", offset);
    getContacts();
  }, [offset]);

  React.useEffect(() => {
    if (offset !== 0) setOffset(0);
    else getContacts();
  }, [sort]);

  return (
    <>
      <ContactSideBar />
      <div className="py-5">
        <div className="container mt-5">
          <h1 className="text-center">Contact</h1>
          <div className="row mt-3">
            <div className="col-12 d-flex justify-content-end">
              <button
                className="filled-button"
                onClick={() => {
                  let s = sort === "Date" ? "Alphabetical" : "Date";
                  setSort(s);
                }}
                disabled={loading}>
                {sort}
              </button>
            </div>
            {contacts.map((item: any) => (
              <div className="col-md-6 mb-3" key={item.IdContact}>
                <div className="card border-0 shadow rounded ">
                  <div className="card-body">
                    <h4 className="card-title">{item.FullName}</h4>
                    <em>
                      <a href={`mailto:${item.Email}`}>{item.Email}</a>
                    </em>
                    <p className="card-text">Subject: {item.Subject}</p>
                    <p className="card-text">Message: {item.Message}</p>
                    {/* <button
                      className=" mt-2 btn btn-primary"
                      onClick={() => {
                        setOpen(!open);
                        setSelectedContact(item);
                      }}>
                      Open Chat
                    </button> */}
                  </div>
                </div>
              </div>
            ))}

            {hasMore && (
              <div className="col-12">
                <button
                  className="filled-button btn-block shadow"
                  onClick={() => setOffset(offset + 1)}
                  disabled={loading}>
                  {loading ? <Spinner /> : "Load More"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
