import React from "react";
import { useNavigate } from "react-router";
import { connect } from "react-redux";

const mapStateToProps = (state: any) => {
  return {
    path: state.path,
    navigation: state.navigation,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    setPath: (path: string) => dispatch({ type: "SET_PATH", path: path }),
  };
};
const Footer = ({ path, setPath, navigation }: any) => {
  const navigate = useNavigate();
  const handleNavigate = (path: string) => {
    navigate(path);
    sessionStorage.setItem("path", path);
    setPath(path);
  };
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-6 footer-item">
              <a className=" ml-3">
                <img
                  style={{ maxHeight: "2.5rem" }}
                  src="/assets/images/EVX logo inverted.png"
                  alt=""
                />
                <h5 className="mt-4 text-uppercase">
                  All Your ev needs in one place
                </h5>
              </a>
              <p></p>
              <ul className="social-icons">
                <li>
                  <a
                    rel="nofollow"
                    href="https://www.instagram.com/evxshowroom?igsh=MTF2cHUxb2Z4bjBwcQ=="
                    target="_blank">
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                {/* <li>
                  <a href="#">
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li> */}
              </ul>
            </div>
            {/* <div className="col-md-3 footer-item">
              <h4>Useful Links</h4>
              <ul className="menu-list">
                <li>
                  <a href="#">Vivamus ut tellus mi</a>
                </li>
                <li>
                  <a href="#">Nulla nec cursus elit</a>
                </li>
                <li>
                  <a href="#">Vulputate sed nec</a>
                </li>
                <li>
                  <a href="#">Cursus augue hasellus</a>
                </li>
                <li>
                  <a href="#">Lacinia ac sapien</a>
                </li>
              </ul>
            </div> */}
            <div className="col-md-6 footer-item">
              <h4>Useful Links</h4>
              <ul className="menu-list">
                {navigation.map((nav: any) => (
                  <li key={nav.id}>
                    <a onClick={() => handleNavigate(nav.path)}>
                      <i className="bi bi-chevron-right" />
                      {nav.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            {/* <div className="col-md-3 footer-item last-item">
              <h4>Contact Us</h4>
              <div className="contact-form">
                <form id="contact footer-contact" action="" method="post">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <fieldset>
                        <input
                          name="name"
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Full Name"
                          required
                        />
                      </fieldset>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <fieldset>
                        <input
                          name="email"
                          type="text"
                          className="form-control"
                          id="email"
                          pattern="[^ @]*@[^ @]*"
                          placeholder="E-Mail Address"
                          required
                        />
                      </fieldset>
                    </div>
                    <div className="col-lg-12">
                      <fieldset>
                        <textarea
                          name="message"
                          rows={6}
                          className="form-control"
                          id="message"
                          placeholder="Your Message"
                          required></textarea>
                      </fieldset>
                    </div>
                    <div className="col-lg-12">
                      <fieldset>
                        <button
                          type="submit"
                          id="form-submit"
                          className="filled-button">
                          Send Message
                        </button>
                      </fieldset>
                    </div>
                  </div>
                </form>
              </div>
            </div> */}
          </div>
        </div>
      </footer>
      <div className="sub-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p>
                Copyright © EVX - Designed by{" "}
                <a href="https://asynk.tech">Asynk Tech</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
