import React from "react";
import { fetchApi } from "../../../Utils/Connections";
import Spinner from "../componants/Spinner";

import moment from "moment";
export default function PreRegister() {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);

  const [sort, setSort] = React.useState<string>("Date");
  const [offset, setOffset] = React.useState<number>(0);

  const [preRegister, setpreRegister] = React.useState<any>([]);

  const [hasMore, setHasMore] = React.useState<boolean>(true);

  const getContacts = async () => {
    setLoading(true);
    setHasMore(true);
    const result = await fetchApi(
      `Admin/GetPreRegisters?Offset=${offset}&OrderType=${sort}`,
      "GET",
      {}
    );
    if (result.StatusCode === 200) {
      if (result.My_Result.length === 0 && offset !== 0) {
        setHasMore(false);
        setLoading(false);
        return;
      }
      let c = [];
      if (offset === 0) {
        c = result.My_Result;
      } else {
        c = [...preRegister, ...result.My_Result];
      }
      setpreRegister(c);
    } else {
      setError(true);
      alert("Something went wrong, try again.");
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getContacts();
  }, [offset]);

  React.useEffect(() => {
    if (offset !== 0) setOffset(0);
    else getContacts();
  }, [sort]);

  return (
    <div className="py-5">
      <div className="container mt-5">
        <h1 className="text-center">Pre-Register</h1>
        <div className="row mt-3">
          <div className="col-12 d-flex justify-content-end">
            <button
              className="filled-button"
              onClick={() => setSort(sort === "Date" ? "Alphabetical" : "Date")}
              disabled={loading}>
              {sort}
            </button>
          </div>
          {preRegister.map((item: any) => (
            <div className="col-md-4 mb-3" key={item.IdPreRegister}>
              <div className="card border-0 shadow rounded">
                <div className="card-body">
                  <h4 className="card-title m-0">{item.FullName}</h4>
                  <div>{item.AccountType}</div>
                  <div>
                    <em>
                      <a href={`mailto:${item.Email}`}>
                        <i className="fa fa-envelope mr-1"></i>
                        {item.Email}
                      </a>
                    </em>
                  </div>
                  <div>
                    <i className="fa fa-phone mr-1"></i>
                    <a href="tel:123456789">{item.Phone}</a>
                  </div>
                  <div>
                    <i className="fa fa-map-marker mr-1"> </i>
                    {item.Address}
                  </div>
                  {item.IdAccountType === 1 ? (
                    <>
                      <div>
                        <i className="fa fa-calendar mr-1"></i>
                        {moment(item.DateOfBirth).format("DD/MM/YYYY")}
                      </div>
                      <div>{item.JobTitle}</div>
                    </>
                  ) : (
                    <div>{item.BusinessName}</div>
                  )}
                  {/* <button className="btn btn-primary btn-block mt-2">
                    Accept
                  </button> */}
                </div>
              </div>
            </div>
          ))}
          {hasMore && (
            <div className="col-12">
              <button
                className="filled-button btn-block shadow"
                onClick={() => setOffset(offset + 1)}
                disabled={loading}>
                {loading ? <Spinner /> : "Load More"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
