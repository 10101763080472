import React from "react";

export default function ContactUs() {
  return (
    <>
      <div className="page-heading header-text">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>About Us</h1>
              <span>Ride the Wave of Change</span>
            </div>
          </div>
        </div>
      </div>

      <div className="more-info about-info mb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="more-info-content">
                <div className="row">
                  <div className="col-md-6 align-self-center">
                    <div className="right-content">
                      {/* <span>Lorem ipsum dolor sit amet</span> */}
                      <h2>
                        <em>E-Mobility</em>
                        <br /> Our Focus,
                        <br /> Your Sustainable Tomorrow
                      </h2>
                      <p>
                        We are a Lebanese startup dedicated to pushing us toward
                        a sustainable future,
                        <br /> where electric vehicle technology is not only
                        mainstream but also effortlessly integrated into
                        everyday life.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="left-image">
                      <img
                        src="assets/images/DALL·E 2024-01-18 00.06.15 - An image for the 'About Us' section of an electric vehicle (EV) association website. The image should convey a sense of community, innovation, and com.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="fun-facts">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="left-content">
                <span>Lorem ipsum dolor sit amet</span>
                <h2>
                  Modi esse sapiente tenetur <em>impedit laudantium laborum</em>
                </h2>
                <p>
                  Pellentesque ultrices at turpis in vestibulum. Aenean pretium
                  elit nec congue elementum. Nulla luctus laoreet porta.
                  Maecenas at nisi tempus, porta metus vitae, faucibus augue.
                  <br />
                  <br />
                  Fusce et venenatis ex. Quisque varius, velit quis dictum
                  sagittis, odio velit molestie nunc, ut posuere ante tortor ut
                  neque.
                </p>
              </div>
            </div>
            <div className="col-md-6 align-self-center">
              <div className="row">
                <div className="col-md-6">
                  <div className="count-area-content">
                    <div className="count-digit">100000</div>
                    <div className="count-title">Miles driven</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="count-area-content">
                    <div className="count-digit">1280</div>
                    <div className="count-title">Happy clients</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="count-area-content">
                    <div className="count-digit">12</div>
                    <div className="count-title">Cities</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="count-area-content">
                    <div className="count-digit">26</div>
                    <div className="count-title">Cars</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
