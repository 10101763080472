import React from "react";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
const mapStateToProps = (state: any) => {
  return {
    path: state.path,
    navigation: state.navigation,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPath: (path: string) => dispatch({ type: "SET_PATH", path: path }),
  };
};

const NavBar = ({ navigation, path, setPath }: any) => {
  const navigate = useNavigate();
  const handleNavigate = (path: string) => {
    navigate(path);
    sessionStorage.setItem("path", path);
    setPath(path);
    const element = document.getElementById("root");
    window.scrollTo(0, 0);
  };
  return (
    <>
      {/* <div className="sub-header">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-xs-12">
              <ul className="left-info">
                <li>
                  <a href="#">
                    <i className="fa fa-envelope"></i>contact@company.com
                  </a>
                </li>
                <li>
                  <a href="tel:961 76 663 881">
                    <i className="fa fa-phone"></i>+961 76 663 881
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <ul className="right-icons">
                <li>
                  <a href="https://www.instagram.com/evxshowroom?igsh=MTF2cHUxb2Z4bjBwcQ==">
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div> */}
      <header className="">
        <nav className="navbar navbar-expand-lg">
          <div className="container ">
            <a className=" ml-3">
              {/* <h2>
                Car <em> Website</em>
              </h2> */}
              <img
                style={{ maxHeight: "2.5rem" }}
                src="/assets/images/EVX logo2.png"
                alt=""
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ml-auto">
                {/* <li className="nav-item active">
                  <a className="nav-link" href="index.html">
                    Home
                    <span className="sr-only">(current)</span>
                  </a>
                </li> */}
                {navigation.map((nav: any) => (
                  <li className="nav-item" key={nav.id}>
                    <a
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarResponsive"
                      aria-controls="navbarResponsive"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                      className={`nav-link ${path === nav.path && "active"}`}
                      onClick={() => handleNavigate(nav.path)}>
                      {nav.name}
                    </a>
                  </li>
                ))}
                {/* <li className="nav-item dropdown">
                  <a
                    className="dropdown-toggle nav-link"
                    data-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false">
                    About
                  </a>

                  <div className="dropdown-menu">
                    <a className="dropdown-item" href="about.html">
                      About Us
                    </a>
                    <a className="dropdown-item" href="blog.html">
                      Blog
                    </a>
                    <a className="dropdown-item" href="team.html">
                      Team
                    </a>
                    <a className="dropdown-item" href="testimonials.html">
                      Testimonials
                    </a>
                    <a className="dropdown-item" href="faq.html">
                      FAQ
                    </a>
                    <a className="dropdown-item" href="terms.html">
                      Terms
                    </a>
                  </div>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="contact.html">
                    Contact Us
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
