import React from "react";
import { connect } from "react-redux";

const mapStateToProps = (state: any) => {
  return {
    open: state.sideBar,
    selectedContact: state.selectedContact,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    setOpen: (sidebar: boolean) =>
      dispatch({ type: "SET_SIDEBAR", sideBar: sidebar }),
    setSelectedContact: (contact: any) =>
      dispatch({ type: "SET_SELECTED_CONTACT", selectedContact: contact }),
  };
};
const ContactSideBar = ({
  selectedContact,
  setSelectedContact,
  open,
  setOpen,
}: any) => {
  const [message, setMessage] = React.useState<string>("");

  const sendMessage = () => {
    let messages = selectedContact.messages;
    messages.push({
      text: message,
      type: "me",
      time: new Date().toLocaleTimeString(),
    });
    setSelectedContact({
      ...selectedContact,
      messages,
    });
    setMessage("");
  };
  return (
    <>
      <div
        className={`backdrop ${open ? "open" : ""}`}
        onClick={() => setOpen(!open)}
      />
      <aside className={`sidebar ${open ? "open" : ""}`}>
        <div className="d-flex justify-content-between align-items-center pb-2 border-bottom">
          <h3 className="">
            <span>{selectedContact !== null && selectedContact.name}</span>
          </h3>
          <div
            className="fa fa-close"
            onClick={() => {
              setOpen(!open);
            }}>
            <span />
            <span />
          </div>
        </div>
        <div>
          <div className="messages">
            {selectedContact !== null &&
              selectedContact.messages.map((item: any) => (
                <div className={`message ${item.type === "me" ? "me" : "you"}`}>
                  <div className="bubble ">
                    <p>{item.text}</p>
                    <small className="d-flex justify-content-end text-muted">
                      {item.time}
                    </small>
                  </div>
                </div>
              ))}
          </div>
          <div className="send d-flex pl-0 pr-5">
            <input
              className="form-control"
              placeholder="Type a message"
              onChange={(e) => setMessage(e.target.value)}
            />
            <button
              className="btn btn-primary ml-2"
              onClick={() => {
                sendMessage();
              }}>
              Send
            </button>
          </div>
        </div>
      </aside>
    </>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(ContactSideBar);
