const PROD_FETCH_URL = "https://api.evxshowroom.com/";
// export const USER_NAME_SOCKET_URL = `${PROD_FETCH_URL}Sockets/UserNames`;
// export const NOTIFICATIONS_SOCKET_URL = `${PROD_FETCH_URL}Sockets/Users`;
// export const POSTS_READ_SOCKET_URL = `${PROD_FETCH_URL}Sockets/Posts`;
// export const ADMIN_SOCKET_URL = `${PROD_FETCH_URL}Sockets/Admins`;

export const getLocalData = async (key: string) => {
  try {
    const value = await sessionStorage.getItem(key);
    if (value !== null) {
      return value;
    }
  } catch (e) {
    // console.warn(e);
  }
};

export const fetchApi = async (path: string, method: string, body: any) => {
  const authorization = await getLocalData("Token");
  const xAccessToken = await getLocalData("Token");

  const headers: Record<string, string> = {
    "Content-Type": "application/json",
  };

  if (authorization) headers.Authorization = authorization;
  if (xAccessToken) headers["x-access-token"] = xAccessToken;

  const options: { method: string; headers: any; body?: string } = {
    method: method,
    headers: headers,
  };

  if (method === "POST" || method === "PUT" || method === "DELETE") {
    // console.log("body", body);
    options.body = JSON.stringify(body);
  }

  // console.warn(`${PROD_FETCH_URL}${path}`, options);

  try {
    // console.log("options", options);
    const response = await fetch(`${PROD_FETCH_URL}${path}`, options);

    const json = await response.json();
    // console.warn(json);
    if (json.StatusCode === 403) {
      window.location.reload();
    }
    // console.log("json", json);
    return json;
  } catch (e) {
    // console.warn(e);
    if (e instanceof Error) {
      throw e; // or throw new Error(e.message);
    } else {
      throw new Error(String(e));
    }
  }
};
