const initialState = {
  path: sessionStorage.getItem("path") || "/",
  navigation: [
    {
      id: 1,
      name: "Home",
      path: "/",
    },
    {
      id: 2,
      name: "About",
      path: "/about",
    },
    {
      id: 3,
      name: "Contact",
      path: "/contact",
    },
    {
      id: 4,
      name: "Pre-Register",
      path: "/preRegister",
    },
  ],
  services: [
    {
      id: 1,
      name: "Looking to make the switch to an EV?",
      desc: [
        `Explore a diverse range of products curated by the most trusted EV sellers in Lebanon.`,
        `Whether you're in the market for e-bikes or pickup trucks, your ideal electric vehicle awaits.`,
        `Don't hesitate, take the leap into the future of transportation.`,
        `Pre-register now to embark on your electrifying journey.`,
      ],
      title: "EV Sales & Purchasing",
      image: "/assets/images/blog-image-1-940x460.jpg",
    },
    {
      id: 2,
      name: "Already an EV owner?",
      desc: [
        `Your EV maintenance and charging needs, simplified and streamlined for your convenience.`,
        `In need of a recharge?`,
        `Access a multitude of charging locations and networks seamlessly through a single, user-friendly platform.`,
        `Require maintenance or repairs?`,
        `Access certified repair service businesses and skilled professionals with ease.
      `,
      ],
      title: "Maintenance & Charging Solutions",
      image: "/assets/images/blog-image-2-940x460.jpg",
    },
    {
      id: 3,
      name: "Are you selling EVs or related products?",
      desc: `Showcase your products to the market by publishing them on EVX. With our extensive community of users, your posts will reach a wide audience, ensuring maximum visibility for your offerings.`,
      image: "/assets/images/blog-image-3-940x460.jpg",
    },
    {
      id: 4,
      name: "Added Features:",
      desc: [
        `Forums to help you connect with EV owners, sellers, experts…`,
        `Guides, tutorials, assistance hotline`,
        `EV news`,
        `And more…`,
        `With EVX, you become a valued member of a community of passionate EV enthusiasts, collaborating to share insights, exchange knowledge, and foster a sustainable future for electric vehicles.`,
      ],
      image: "/assets/images/blog-image-4-940x460.jpg",
    },
  ],
};

const mainReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_PATH":
      return {
        ...state,
        path: action.path,
      };
    default:
      return state;
  }
};

export default mainReducer;
